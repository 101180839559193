body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    text-decoration: none !important;
    color: #fff;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: right;
    height: max-content;
    background-color: #f5f5f5;
}

.link {
    margin-left: 20px
}

img {
    color: #303030
}